import React from 'react'

const ConnectEmailAccountModal = ({ isOpen, onClose, onAuthorize }) => {
	if (!isOpen) return null

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
			<div className="bg-[#002b44] rounded-lg p-8 max-w-md w-full text-white">
				<h2 className="text-2xl font-bold mb-4">Connect Email Account</h2>
				<p className="mb-6 text-gray-300">
					Connect your email account to enable email sending functionality.
				</p>
				<div className="flex flex-col space-y-4">
					<button
						onClick={() => onAuthorize('gmail')}
						className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
					>
						Connect Gmail Account
					</button>
					{/* <button
						onClick={() => onAuthorize('custom')}
						className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
					>
						Set Up Custom Email
					</button> */}
					<button
						onClick={onClose}
						className="bg-[#003a5c] hover:bg-[#004a6e] text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	)
}

export default ConnectEmailAccountModal
