import React, { useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
	CogIcon,
	XMarkIcon,
	ArrowRightStartOnRectangleIcon,
	ChartBarIcon,
	BriefcaseIcon,
} from '@heroicons/react/24/outline'

const navItems = [
	// { name: 'Analytics', icon: ChartBarIcon, path: '/analytics' },
	{ name: 'Broker Info', icon: BriefcaseIcon, path: '/broker-info' },
	{ name: 'Settings', icon: CogIcon, path: '/settings' },
]

function SidePanel({ isOpen, onClose }) {
	const location = useLocation()
	const panelRef = useRef(null)

	const navigate = useNavigate()

	const jwtToken = localStorage.getItem('jwtToken')
	const user = jwtToken ? JSON.parse(atob(jwtToken.split('.')[1])) : null

	const handleLogout = () => {
		localStorage.removeItem('jwtToken')
		navigate('/')
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (panelRef.current && !panelRef.current.contains(event.target)) {
				onClose()
			}
		}

		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isOpen, onClose])

	return (
		<>
			{isOpen && (
				<div
					className="fixed inset-0 bg-black bg-opacity-50 z-10"
					onClick={onClose}
				></div>
			)}
			<div
				ref={panelRef}
				className={`bg-gray-900 text-gray-300 h-screen w-80 fixed left-0 top-0 transform ${
					isOpen ? 'translate-x-0' : '-translate-x-full'
				} transition-transform duration-300 ease-in-out z-20 flex flex-col`}
			>
				<div className="p-6 flex justify-between items-center">
					<h1 className="text-lg font-semibold text-sky-400">
						Hello, {user?.email}
					</h1>
					<button
						onClick={onClose}
						className="text-gray-300 hover:text-white focus:outline-none"
					>
						<XMarkIcon className="h-8 w-8" />
					</button>
				</div>

				<nav className="flex-grow">
					<ul className="space-y-2">
						{navItems.map(item => (
							<li key={item.name}>
								<Link
									to={item.path}
									className={`flex items-center px-6 py-3 text-lg ${
										location.pathname === item.path
											? 'bg-gray-800 text-white'
											: 'hover:bg-gray-800'
									}`}
									onClick={onClose}
								>
									<item.icon className="h-7 w-7 mr-4" />
									{item.name}
								</Link>
							</li>
						))}
					</ul>
				</nav>

				<div className="p-6 border-t border-gray-700">
					<Link
						to="/logout"
						className="flex items-center text-lg text-gray-300 hover:text-white"
						onClick={handleLogout}
					>
						<ArrowRightStartOnRectangleIcon className="h-7 w-7 mr-4" />
						Logout
					</Link>
				</div>
			</div>
		</>
	)
}

export default SidePanel
